import React from 'react';
import { graphql } from 'gatsby';
import { shape, arrayOf, any } from 'prop-types';

import Layout from '../components/layout';
import PostListing from '../components/PostListing';
import PostsContainer from '../components/PostsContainer';

export default function IndexPage({ data }) {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <Layout>
      <h1>Recent posts</h1>
      <PostsContainer>
        {posts.map(
          ({
            node: {
              timeToRead,
              excerpt,
              frontmatter: { path, tags, title },
            },
          }) => (
            <PostListing
              key={path}
              timeToRead={timeToRead}
              path={path}
              tags={tags}
              title={title}
              excerpt={excerpt}
            />
          )
        )}
      </PostsContainer>
    </Layout>
  );
}

IndexPage.propTypes = {
  data: shape({ allMarkdownRemark: shape({ edges: arrayOf(any) }) }).isRequired,
};

export const pageQuery = graphql`
  query IndexPageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 2
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            path
            title
            tags
          }
          timeToRead
        }
      }
    }
  }
`;
