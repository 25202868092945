import { styled } from 'styletron-react';
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants';

export default styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '1rem',
  paddingTop: '1rem',
  [MOBILE_MEDIA_QUERY]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    paddingTop: '0',
  },
});
