import React from 'react';
import { styled } from 'styletron-react';
import { Link } from 'gatsby';
import { arrayOf, string, number } from 'prop-types';

import { kebabize } from '../utils/text';

const ListingContainer = styled('div', {
  background: 'white',
  boxShadow: '0 .4rem .8rem rgba(102,119,136,0.1)',
  padding: '1rem',
  borderRadius: '.4rem',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});

const badgeStyle = {
  position: 'absolute',
  top: '.2rem',
  color: 'rgba(0,0,0,.5)',
};

const LeftBadge = styled('span', { ...badgeStyle, left: '.6rem' });

const RightBadge = styled('span', { ...badgeStyle, right: '.6rem' });

const resetAnchorStyles = {
  textShadow: 'none',
  backgroundImage: 'none',
  textDecoration: 'none',
};

const tagStyles = {
  background: '#195ece',
  padding: '.2rem .4rem',
  lineHeight: '1rem',
  marginRight: '.2rem',
  borderRadius: '.1rem',
  fontSize: '.8rem',
  display: 'inline-block',
  color: '#fff',
  ...resetAnchorStyles,
};

const LinkedTag = styled(Link, tagStyles);

const formatTTR = mins => `${mins} min ${mins > 1 && 's'} read.`;

export default function PostListing({
  path,
  title,
  tags,
  date,
  timeToRead,
  excerpt,
}) {
  return (
    <ListingContainer>
      <LeftBadge>{formatTTR(timeToRead)}</LeftBadge>
      {date && <RightBadge>{date}</RightBadge>}
      <h4>
        <Link to={path}>{title}</Link>
      </h4>
      <p style={{ flex: 1 }}>{excerpt}</p>
      <div>
        {tags.filter(Boolean).map(tag => (
          <LinkedTag key={tag} to={`/tag/${kebabize(tag)}`}>
            {tag}
          </LinkedTag>
        ))}
      </div>
    </ListingContainer>
  );
}

PostListing.propTypes = {
  path: string.isRequired,
  title: string.isRequired,
  tags: arrayOf(string).isRequired,
  date: string.isRequired,
  timeToRead: number.isRequired,
  excerpt: string.isRequired,
};
